import { useTranslation } from "react-i18next";
import "./Sectors.css";
import sectors from "../../assets/images/Sectors/sectors.png";
import industry from "../../assets/images/Sectors/industry.jpg";
import { useEffect, useState } from "react";
import { HiOutlineLightBulb } from "react-icons/hi2";
import health from "../../assets/images/Sectors/health.jpg";
import it from "../../assets/images/Sectors/it.jpg";
import food from "../../assets/images/Sectors/food.jpg";
import agricultural from "../../assets/images/Sectors/agricultural.jpg";
import manufacturing from "../../assets/images/Sectors/manufacturing.jpg";
import construction from "../../assets/images/Sectors/construction.jpg";
import { useLocation } from "react-router-dom";
import forest from "../../assets/images/Sectors/forest.jpg";
import chemical from "../../assets/images/Sectors/chemical.jpg";

export default function Services() {
    const { t } = useTranslation('common');
    const [width, setWidth] = useState(window.innerWidth);
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);

        const params = new URLSearchParams(location.search);
        const elementId = params.get('scrollTo');
        if (elementId)
            scrollToElement(elementId)

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    function scrollToElement(elementId) {
        var element = document.getElementById(elementId);
        var offset = 150;
        var elementPosition = element.getBoundingClientRect().top + window.scrollY;
        var offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }

    return (
        <div className="servicesContainer">
            <img style={{marginBottom: width > 1100 ? 40 : 90}} src={width > 1100 ? sectors : sectors} width={width > 1100 ? width - 20 : width} />
            <div style={width > 1100 ? { flexDirection: 'row' } : { flexDirection: 'column' }} className="headerBlocks">
                <div className="block">
                    <HiOutlineLightBulb color="white" size={90} />
                    <p className="blockText">{t('header.sectors')}</p>
                </div>
            </div>
            <div className="servicesBody" style={{ display: 'flex' }}>
                <div id="industry" className="sectorsBodySection" style={width > 1100 ? { flexDirection: 'row', display: 'flex', flex: 1 } : { flexDirection: 'column', display: 'flex', flex: 1 }}>
                    {width <= 1100 ? <div /> : <div style={{ flex: 1, display: 'flex' }} className="blockBody">
                        <img src={industry} width={width > 1100 ? width / 100 * 30 : width} />
                    </div>}
                    <div className="bodyContent" style={{ flex: 1, display: 'flex' }}>
                        <p className="sectorsTitle">{t('sectors.industryTitle')}</p>
                        <p className="sectorsText">{t('sectors.industryText')}</p>
                    </div>
                    {width > 1100 ? <div /> : <div style={{ flex: 1, display: 'flex' }} className="blockBody">
                        <img src={industry} width={width > 1100 ? width / 100 * 30 : width} />
                    </div>}
                </div>
                <div id="health" className="sectorsBodySection" style={width > 1100 ? { flexDirection: 'row', display: 'flex', flex: 1 } : { flexDirection: 'column', display: 'flex', flex: 1 }}>
                    <div className="bodyContent" style={{ display: 'flex', flex: 1 }}>
                        <p className="sectorsTitle">{t('sectors.healthTitle')}</p>
                        <p className="sectorsText">{t('sectors.healthText')}</p>
                    </div>
                    <div style={{ display: 'flex', flex: 1 }} className="blockBody">
                        <img src={health} width={width > 1100 ? width / 100 * 30 : width} />
                    </div>
                </div>
                <div id="it" className="sectorsBodySection" style={width > 1100 ? { flexDirection: 'row', display: 'flex', flex: 1 } : { flexDirection: 'column', display: 'flex', flex: 1 }}>
                    {width <= 1100 ? <div /> : <div style={{ display: 'flex', flex: 1 }} className="blockBody">
                        <img src={it} width={width > 1100 ? width / 100 * 30 : width} />
                    </div>}
                    <div className="bodyContent" style={{ display: 'flex', flex: 1 }}>
                        <p className="sectorsTitle">{t('sectors.ticTitle')}</p>
                        <p className="sectorsText">{t('sectors.ticText')}</p>
                    </div>
                    {width > 1100 ? <div /> : <div style={{ display: 'flex', flex: 1 }} className="blockBody">
                        <img src={it} width={width > 1100 ? width / 100 * 30 : width} />
                    </div>}
                </div>
                <div id="food" className="sectorsBodySection" style={width > 1100 ? { flexDirection: 'row', display: 'flex', flex: 1 } : { flexDirection: 'column', display: 'flex', flex: 1 }}>
                    <div className="bodyContent" style={{ display: 'flex', flex: 1 }}>
                        <p className="sectorsTitle">{t('sectors.foodTitle')}</p>
                        <p className="sectorsText">{t('sectors.foodText')}</p>
                    </div>
                    <div style={{ display: 'flex', flex: 1 }} className="blockBody">
                        <img src={food} width={width > 1100 ? width / 100 * 30 : width} />
                    </div>
                </div>
                <div id="agricultural" className="sectorsBodySection" style={width > 1100 ? { flexDirection: 'row', display: 'flex', flex: 1 } : { flexDirection: 'column', display: 'flex', flex: 1 }}>
                    {width <= 1100 ? <div /> : <div style={{ display: 'flex', flex: 1 }} className="blockBody">
                        <img src={agricultural} width={width > 1100 ? width / 100 * 30 : width} />
                    </div>}
                    <div className="bodyContent" style={{ display: 'flex', flex: 1 }}>
                        <p className="sectorsTitle">{t('sectors.agriculturalTitle')}</p>
                        <p className="sectorsText">{t('sectors.agriculturalText')}</p>
                    </div>
                    {width > 1100 ? <div /> : <div style={{ display: 'flex', flex: 1 }} className="blockBody">
                        <img src={agricultural} width={width > 1100 ? width / 100 * 30 : width} />
                    </div>}
                </div>
                <div id="manufacturing" className="sectorsBodySection" style={width > 1100 ? { flexDirection: 'row', display: 'flex', flex: 1 } : { flexDirection: 'column', display: 'flex', flex: 1 }}>
                    <div className="bodyContent" style={{ display: 'flex', flex: 1 }}>
                        <p className="sectorsTitle">{t('sectors.manufacturingTitle')}</p>
                        <p className="sectorsText">{t('sectors.manufacturingText')}</p>
                    </div>
                    <div style={{ display: 'flex', flex: 1 }} className="blockBody">
                        <img src={manufacturing} width={width > 1100 ? width / 100 * 30 : width} />
                    </div>
                </div>
                <div id="construction" className="sectorsBodySection" style={width > 1100 ? { flexDirection: 'row', display: 'flex', flex: 1 } : { flexDirection: 'column', display: 'flex', flex: 1 }}>
                    {width <= 1100 ? <div /> : <div style={{ display: 'flex', flex: 1 }} className="blockBody">
                        <img src={construction} width={width > 1100 ? width / 100 * 30 : width} />
                    </div>}
                    <div className="bodyContent" style={{ display: 'flex', flex: 1 }}>
                        <p className="sectorsTitle">{t('sectors.constructionTitle')}</p>
                        <p className="sectorsText">{t('sectors.constructionText')}</p>
                    </div>
                    {width > 1100 ? <div /> : <div style={{ display: 'flex', flex: 1 }} className="blockBody">
                        <img src={construction} width={width > 1100 ? width / 100 * 30 : width} />
                    </div>}
                </div>
                <div id="forest" className="sectorsBodySection" style={width > 1100 ? { flexDirection: 'row', display: 'flex', flex: 1 } : { flexDirection: 'column', display: 'flex', flex: 1 }}>
                    <div className="bodyContent" style={{ display: 'flex', flex: 1 }}>
                        <p className="sectorsTitle">{t('sectors.forestTitle')}</p>
                        <p className="sectorsText">{t('sectors.forestText')}</p>
                    </div>
                    <div style={{ display: 'flex', flex: 1 }} className="blockBody">
                        <img src={forest} width={width > 1100 ? width / 100 * 32 : width} />
                    </div>
                </div>
                <div id="chemical" className="sectorsBodySection" style={width > 1100 ? { flexDirection: 'row', display: 'flex', flex: 1 } : { flexDirection: 'column', display: 'flex', flex: 1 }}>
                    {width <= 1100 ? <div /> : <div style={{ display: 'flex', flex: 1, marginTop: 50 }} className="blockBody">
                        <img src={chemical} width={width > 1100 ? width / 100 * 20 : width} />
                    </div>}
                    <div className="bodyContent" style={{ display: 'flex', flex: 1 }}>
                        <p className="sectorsTitle">{t('sectors.chemicalTitle')}</p>
                        <p className="sectorsText">{t('sectors.chemicalText')}</p>
                    </div>
                    {width > 1100 ? <div /> : <div style={{ display: 'flex', flex: 1, marginTop: 50 }} className="blockBody">
                        <img src={chemical} width={width > 1100 ? width / 100 * 20 : width} />
                    </div>}
                </div>
            </div>
        </div>
    )
}