import { CKEditor } from "@ckeditor/ckeditor5-react";
import "./TextInput.css"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function TextInput({ onKeyUp, inputWidth = '40rem', titleFontSize = '1.3rem', inputFontSize = '1rem', maxLength = 524288, value, error, errorMessage, onChange, placeholder, type = 'input', children, ...props }) {
    function getComponentByType() {
        switch (type) {
            case "input":
                return getTextInput();
            case "textarea":
                return getTextArea();
            case "CKEditor":
                return getCKEditor();
        }
    }

    function getTextInput() {
        return (
            <input onKeyUp={onKeyUp} style={{ fontSize: inputFontSize, width: inputWidth }} maxLength={maxLength} value={value} onChange={text => handleTextareaChange(text, onChange)} placeholder={placeholder} type="text" />
        );
    }

    function getTextArea() {
        return (
            <textarea onKeyUp={onKeyUp} style={{ fontSize: inputFontSize, minWidth: inputWidth, maxWidth: inputWidth }} maxLength={maxLength} value={value} onChange={text => handleTextareaChange(text, onChange)} className="textArea" />
        );
    }

    function getCKEditor() {
        return (
            <CKEditor
                    onChange={(event, editor) => {
                        const newData = editor.getData();
                        onChange(newData);
                    }} data={value}
                    editor={ClassicEditor}
                />
        );
    }

    return (
        <div className="textInputContainer">
            <p className="textInputLabel" style={{ fontSize: titleFontSize }}>{children}</p>
            {getComponentByType()}
            {GetErrorMessageIfError(error, errorMessage)}
        </div>
    )
}

function handleTextareaChange(e, onChange) {
    onChange(e.target.value);
}

function GetErrorMessageIfError(error, errorMessage) {
    if (error)
        return <p style={error ? { color: "red" } : {}} className="textInputError">{errorMessage}</p>
}