import { useTranslation } from "react-i18next";
import "./NotFound.css";
export default function NotFound() {
    const { t } = useTranslation('common');

    return (
        <div className="notFoundContainer">
            <p className="notFoundTitle">{t('notFound.message')}</p>
        </div>
    )
}