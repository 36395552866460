import React, { Component } from "react";
import "./Contact.css"
import TextInput from "../../components/TextInput/TextInput";
import { Loader } from '../../components/Loader/Loader';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import CheckBox from "../../components/CheckBox/CheckBox";
import { Link } from "react-router-dom";
import { RiSendPlaneFill } from "react-icons/ri";
import emailjs from 'emailjs-com';

class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            phone: "",
            company: "",
            subject: "",
            message: "",
            emailError: false,
            nameError: false,
            phoneError: false,
            subjectError: false,
            messageError: false,
            loading: false,
            privacyPolicy: false,
            privacyPolicyError: false,
            disclaimer: false,
            disclaimerError: false,
            width: window.innerWidth,
        }
    }

    updateWidth = () => {
        this.setState({ width: window.innerWidth });
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateWidth);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWidth);
    }

    onSubmit = async (t) => {
        await this.setLoading(true);
        let validationResult = await this.ValidateFields();
        if (validationResult) {
            const { name, email, phone, subject, message, company } = this.state;

            const templateParams = {
                name: name,
                email: email,
                phone: phone,
                subject: subject,
                message: message,
                company: company
            };

            await emailjs.send('service_0cd00ev', 'template_ow79z5q', templateParams, 'YIYKapSer-JLbvMBX')
                .then((result) => {
                    toast.success(t('apiResponse.success'), {
                        position: "bottom-left"
                    });
                }, (error) => {
                    toast.error(error, {
                        position: "bottom-left"
                    });
                });

            await this.cleanInputs();
        }
        await this.setLoading(false);
    }

    cleanInputs = async () => {
        await this.setName("");
        await this.setEmail("");
        await this.setPhone("");
        await this.setCompany("");
        await this.setSubject("");
        await this.setMessage("");
        await this.setState({
            privacyPolicy: false
        });
        await this.setState({
            disclaimer: false
        });
    }

    ValidateFields = () => {
        let ok = true;

        if (this.state.name === null || this.state.name.match(/^ *$/) !== null) {
            ok = false
            this.setState({
                nameError: true,
            });
        }
        else {
            this.setState({
                nameError: false,
            });
        }

        if (!this.state.privacyPolicy) {
            ok = false
            this.setState({
                privacyPolicyError: true,
            });
        }
        else {
            this.setState({
                privacyPolicyError: false,
            });
        }

        if (!this.state.disclaimer) {
            ok = false
            this.setState({
                disclaimerError: true,
            });
        }
        else {
            this.setState({
                disclaimerError: false,
            });
        }

        if (this.state.phone === null || this.state.phone.match(/^ *$/) !== null) {
            ok = false
            this.setState({
                phoneError: true,
            });
        }
        else {
            this.setState({
                phoneError: false,
            });
        }

        if (this.state.email === null || this.state.email.match(/^ *$/) !== null || !this.validateEmail(this.state.email)) {
            ok = false
            this.setState({
                emailError: true,
            });
        }
        else {
            this.setState({
                emailError: false,
            });
        }

        if (this.state.message === null || this.state.message.match(/^ *$/) !== null) {
            ok = false
            this.setState({
                messageError: true,
            });
        }
        else {
            this.setState({
                messageError: false,
            });
        }

        if (this.state.subject === null || this.state.subject.match(/^ *$/) !== null) {
            ok = false
            this.setState({
                subjectError: true,
            });
        }
        else {
            this.setState({
                subjectError: false,
            });
        }

        return ok;
    }

    validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    setName = (name) => {
        this.setState({
            name: name,
        });
    }

    togglePrivacyPolicy = () => {
        if (this.state.privacyPolicy)
            this.setState({
                privacyPolicy: false,
            });
        else
            this.setState({
                privacyPolicy: true,
            });
    }

    toggleDisclaimer = () => {
        if (this.state.disclaimer)
            this.setState({
                disclaimer: false,
            });
        else
            this.setState({
                disclaimer: true,
            });
    }

    setEmail = (email) => {
        this.setState({
            email: email,
        });
    }

    setMessage = (message) => {
        this.setState({
            message: message,
        });
    }

    setSubject = (subject) => {
        this.setState({
            subject: subject,
        });
    }

    setPhone = (phone) => {
        this.setState({
            phone: phone,
        });
    }

    setCompany = (company) => {
        this.setState({
            company: company,
        });
    }

    setLoading = (loading) => {
        this.setState({
            loading: loading,
        });
    }

    render() {
        const t = this.props.t;
        return (
            <div className="contactContainer">
                <Loader visible={this.state.loading} />
                <p className="contactTitle">{t('contact.title')}</p>
                <TextInput inputWidth={this.state.width > 1100 ? "40rem" : "100%"} value={this.state.name} errorMessage={t('contact.nameError')} error={this.state.nameError} onChange={this.setName}>{t('contact.name')}</TextInput>
                <TextInput inputWidth={this.state.width > 1100 ? "40rem" : "100%"} value={this.state.email} errorMessage={t('contact.emailError')} error={this.state.emailError} onChange={this.setEmail}>{t('contact.email')}</TextInput>
                <TextInput inputWidth={this.state.width > 1100 ? "40rem" : "100%"} value={this.state.phone} errorMessage={t('contact.phoneError')} error={this.state.phoneError} onChange={this.setPhone}>{t('contact.phone')}</TextInput>
                <TextInput inputWidth={this.state.width > 1100 ? "40rem" : "100%"} value={this.state.company} onChange={this.setCompany}>{t('contact.company')}</TextInput>
                <TextInput inputWidth={this.state.width > 1100 ? "40rem" : "100%"} value={this.state.subject} errorMessage={t('contact.subjectError')} error={this.state.subjectError} onChange={this.setSubject}>{t('contact.subject')}</TextInput>
                <TextInput inputWidth={this.state.width > 1100 ? "40rem" : "100%"} value={this.state.message} errorMessage={t('contact.messageError')} error={this.state.messageError} onChange={this.setMessage} type={this.state.width > 1100 ? "CKEditor" : "textarea"}>{t('contact.message')}</TextInput>
                <CheckBox checked={this.state.privacyPolicy} error={this.state.privacyPolicyError} errorMessage={t('contact.privacyPolicyError')} onChange={this.togglePrivacyPolicy} >
                    {t('contact.legalNotice')} <Link to="../legal-notice">{t('contact.legalNoticeLink')}</Link>*
                </CheckBox>
                <p style={{ width: this.state.width > 1100 ? this.state.width / 2 : this.state.width / 1.5, textAlign: 'justify' }}>{t('contact.disclaimer')} <Link to="../data-protection-policy">{t('contact.disclaimer2')}</Link> {t('contact.disclaimer3')}</p>
                <CheckBox checked={this.state.disclaimer} error={this.state.disclaimerError} errorMessage={t('contact.disclaimerError')} onChange={this.toggleDisclaimer} >
                    {t('contact.disclaimerCheck')}*
                </CheckBox>
                <button onClick={() => this.onSubmit(t)} className="contactSendButton">{t('contact.send')}<RiSendPlaneFill style={{ marginLeft: 10 }} size={20} /></button>
            </div>
        );
    }
}

export default withTranslation('common')(Contact);