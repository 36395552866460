import { useTranslation } from "react-i18next";
import './Home.css';
import { Modal } from "../../components/Modal/Modal";
import BlogEntries from "../../components/BlogEntries/BlogEntries";
import { useEffect, useState } from "react";
import home from "../../assets/images/Home/home.png";
import homeWider from "../../assets/images/Home/homeWider.png";
import { HiOutlineLightBulb } from "react-icons/hi2";
import { PiFactory } from "react-icons/pi";
import { GiWorld } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { GoGraph, GoTools } from "react-icons/go";
import industry from "../../assets/images/Sectors/industry.jpg";
import health from "../../assets/images/Sectors/health.jpg";
import it from "../../assets/images/Sectors/it.jpg";
import food from "../../assets/images/Sectors/food.jpg";
import agricultural from "../../assets/images/Sectors/agricultural.jpg";
import manufacturing from "../../assets/images/Sectors/manufacturing.jpg";
import construction from "../../assets/images/Sectors/construction.jpg";
import forest from "../../assets/images/Sectors/forest.jpg";
import chemical from "../../assets/images/Sectors/chemical.jpg";

export default function Home() {
    let [modalVisible, setModalVisible] = useState(false);
    let [blogEntry, setBlogEntry] = useState({
        id: 0,
        title: "",
        description: "",
        date: "",
        image: ""
    });
    const [width, setWidth] = useState(window.innerWidth);
    const { t, i18n } = useTranslation('common');
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    async function handleModalOpen(entry) {
        await setBlogEntry(entry);
        await setModalVisible(true);
    }

    function toggleModalVisible() {
        if (modalVisible)
            setModalVisible(false);
        else
            setModalVisible(true);
    }

    const handleNavigate = (page, elementId) => {
        navigate(`/${page}${elementId != null ? `?scrollTo=${elementId}` : ``}`);
    };

    return (
        <div className="homeContainer" style={{ marginBottom: 30 }}>
            {/* <p style={{ marginTop: 50, color: "#0d6bae", fontSize: 30, fontWeight: 'bold' }}>{t('hype')}</p> */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center', backgroundImage: `url(${width > 1100 ? homeWider : home})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', width: "100%", height: 500 }} />
            <div style={width > 1100 ? { flexDirection: 'row' } : { flexDirection: 'column' }} className="headerBlocks">
                <div className="block1">
                    <HiOutlineLightBulb color="white" size={90} />
                    <p className="blockText">{t('services.idi')}</p>
                    <button onClick={() => handleNavigate("services", "bodySection")} className="servicesButton">{t('services.go')}</button>
                </div>
                <div className="block2" style={width > 1100 ? { marginLeft: 40, marginRight: 40 } : { marginTop: 40, marginBottom: 40 }}>
                    <PiFactory color="white" size={90} />
                    <p className="blockText">{t('services.investment')}</p>
                    <button onClick={() => handleNavigate("services", "investment")} className="servicesButton">{t('services.go')}</button>
                </div>
                <div className="block3" style={width > 1100 ? { marginRight: 40 } : { marginBottom: 40 }}>
                    <GiWorld color="white" size={90} />
                    <p className="blockText">{t('services.internationalization')}</p>
                    <button onClick={() => handleNavigate("services", "internationalization")} className="servicesButton">{t('services.go')}</button>
                </div>
                <div className="block4">
                    <GoTools color="white" size={90} />
                    <p className="blockText">{t('services.engineering')}</p>
                    <button onClick={() => handleNavigate("services", "engineering")} className="servicesButton">{t('services.go')}</button>
                </div>
            </div>
            <div className="servicesBody">
                <div style={{ display: "flex", flexDirection: "column", whiteSpace: "pre-line", alignItems: 'center', width: width > 1100 ? "70%" : "85%", alignSelf: 'center' }}>
                    <p style={width > 1100 ?
                        { fontSize: '3rem', fontWeight: 'bold' } :
                        { fontSize: '1.7rem', fontWeight: 'bold' }}>{t('home.title')}</p>
                    <p style={{ textWrap: "wrap", textAlign: 'justify' }}>
                        <span style={{ fontWeight: "bold", color: "#0d6bae" }}>{t('home.description1')}</span>
                        <span>{t('home.description2')}</span>
                        <span style={{ fontWeight: "bold", color: "#0d6bae" }}>{t('home.description3')}</span>
                        <span>{t('home.description4')}</span>
                        <br/>
                        <br/>
                        <span style={{ fontWeight: "bold", color: "#0d6bae" }}>{t('home.description1')}</span>
                        <span>{t('home.description5')}</span>
                        <span style={{ fontWeight: "bold", color: "#0d6bae" }}>{t('home.description6')}</span>
                        <span>{t('home.description7')}</span>
                    </p>
                </div>
            </div>
            <div style={{ borderTop: "8px solid", borderImage: "linear-gradient(to right, #0d6bae, #AECEE4) 50", width: "100%", marginTop: 80, borderTopWidth: 8 }}>
                <div style={width > 1100 ? { flexDirection: 'row' } : { flexDirection: 'column' }} className="homeHeaderBlocks">
                    <div className="block">
                        <GoGraph color="white" size={90} />
                        <p className="blockText">{t('header.sectors')}</p>
                        <button onClick={() => handleNavigate("sectors", null)} className="servicesButton">{t('services.go')}</button>
                    </div>
                </div>
            </div>
            <div style={width > 1100 ? { display: "flex", flexDirection: "row", borderTopLeftRadius: 40, marginTop: 70, justifyContent: "space-evenly", width: "100%" } : { display: "flex", flexDirection: "column", marginTop: 40, justifyContent: "space-evenly", height: 850 }}>
                <div onClick={() => handleNavigate("sectors", "industry")} className="sectorDiv" style={{ display: 'flex', flexDirection: 'column', width: 340, height: 250, cursor: 'pointer', alignItems: 'center', justifyContent: 'center', backgroundImage: `url(${industry})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderTopLeftRadius: 40 }}>
                    <p className="homeSectorsText">{t('sectors.industryTitle')}</p>
                </div>
                <div onClick={() => handleNavigate("sectors", "health")} className="sectorDiv" style={{ display: 'flex', flexDirection: 'column', width: 340, height: 250, cursor: 'pointer', alignItems: 'center', justifyContent: 'center', backgroundImage: `url(${health})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                    <p className="homeSectorsText">{t('sectors.healthTitle')}</p>
                </div>
                <div onClick={() => handleNavigate("sectors", "it")} className="sectorDiv" style={{ display: 'flex', flexDirection: 'column', width: 340, height: 250, cursor: 'pointer', alignItems: 'center', justifyContent: 'center', backgroundImage: `url(${it})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderBottomRightRadius: 40 }}>
                    <p className="homeSectorsText">{t('sectors.ticTitle')}</p>
                </div>
            </div>
            <div style={width > 1100 ? { display: "flex", flexDirection: "row", marginTop: 70, justifyContent: "space-evenly", width: "100%" } : { display: "flex", flexDirection: "column", justifyContent: "space-evenly", height: 850 }}>
                <div onClick={() => handleNavigate("sectors", "food")} className="sectorDiv" style={{ display: 'flex', flexDirection: 'column', width: 340, height: 250, cursor: 'pointer', alignItems: 'center', justifyContent: 'center', backgroundImage: `url(${food})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderTopLeftRadius: 40 }}>
                    <p className="homeSectorsText">{t('sectors.foodTitle')}</p>
                </div>
                <div onClick={() => handleNavigate("sectors", "agricultural")} className="sectorDiv" style={{ display: 'flex', flexDirection: 'column', width: 340, height: 250, cursor: 'pointer', alignItems: 'center', justifyContent: 'center', backgroundImage: `url(${agricultural})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                    <p className="homeSectorsText">{t('sectors.agriculturalTitle')}</p>
                </div>
                <div onClick={() => handleNavigate("sectors", "manufacturing")} className="sectorDiv" style={{ display: 'flex', flexDirection: 'column', width: 340, height: 250, cursor: 'pointer', alignItems: 'center', justifyContent: 'center', backgroundImage: `url(${manufacturing})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderBottomRightRadius: 40 }}>
                    <p className="homeSectorsText">{t('sectors.manufacturingTitle')}</p>
                </div>
            </div>
            <div style={width > 1100 ? { display: "flex", flexDirection: "row", marginTop: 70, justifyContent: "space-evenly", width: "100%" } : { display: "flex", flexDirection: "column", justifyContent: "space-evenly", height: 850 }}>
                <div onClick={() => handleNavigate("sectors", "construction")} className="sectorDiv" style={{ display: 'flex', flexDirection: 'column', width: 340, height: 250, cursor: 'pointer', alignItems: 'center', justifyContent: 'center', backgroundImage: `url(${construction})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderTopLeftRadius: 40 }}>
                    <p className="homeSectorsText">{t('sectors.constructionTitle')}</p>
                </div>
                <div onClick={() => handleNavigate("sectors", "forest")} className="sectorDiv" style={{ display: 'flex', flexDirection: 'column', width: 340, height: 250, cursor: 'pointer', alignItems: 'center', justifyContent: 'center', backgroundImage: `url(${forest})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                    <p className="homeSectorsText">{t('sectors.forestTitle')}</p>
                </div>
                <div onClick={() => handleNavigate("sectors", "chemical")} className="sectorDiv" style={{ display: 'flex', flexDirection: 'column', width: 340, height: 250, cursor: 'pointer', alignItems: 'center', justifyContent: 'center', backgroundImage: `url(${chemical})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderBottomRightRadius: 40 }}>
                    <p className="homeSectorsText">{t('sectors.chemicalTitle')}</p>
                </div>
            </div>


            {/* <Modal toggleVisible={toggleModalVisible} visible={modalVisible} blogEntry={blogEntry} /> */}
            {/* <p className="homeEntriesTitle">{t('home.entriesTitle')}</p> */}
            {/* <BlogEntries onClickEntry={handleModalOpen} /> */}
        </div>
    )
}