// import { Map, Marker } from 'pigeon-maps';
import './Footer.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const customIcon = new L.Icon({
    iconUrl: require('../../assets/images/Logo/logo_p.png'),
    iconSize: [30, 37],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38]
});
const findUsPlace = [39.543931, -0.464421]
export default function Footer() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const { t } = useTranslation('common');
    return (
        <div className='bodyContainer' style={width > 1100 ? { flexDirection: 'row' } : { flexDirection: 'column' }}>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: '50px', alignSelf: 'flex-start' }}>
                <p className='footerTitle'>{t('footer.findUs')}</p>
                <MapContainer attributionControl={false} zoomControl={false} center={findUsPlace} zoom={13} style={width > 1100 ? { height: 300, width: 300 } : { height: 280, width: 280 }}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={findUsPlace} icon={customIcon} eventHandlers={{ click: () => window.open("https://www.google.es/maps/place/Rda.+de+la+Quimica,+46980,+Valencia/@39.543903,-0.4645608,104m/data=!3m1!1e3!4m6!3m5!1s0xd605b30e9364665:0x343e086feff5165!8m2!3d39.5429893!4d-0.4608371!16s%2Fg%2F1q679mpz0?hl=es&entry=ttu&g_ep=EgoyMDI0MDkxOC4xIKXMDSoASAFQAw%3D%3D", "_blank")}}/>
                </MapContainer>
                <p className='footerSubtitle'>{t('footer.address1')}</p>
                <p className='footerSubtitle'>{t('footer.address2')}</p>
            </div>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: '50px', alignSelf: 'flex-start' }}>
                <p className='footerTitle'>{t('footer.links.title')}</p>
                <ul>
                    <li><Link className='footerSubtitle' to="../legal-notice">{t('footer.links.legalNotice')}</Link></li>
                    <li><Link className='footerSubtitle' to="../data-protection-policy">{t('footer.links.dataProtectionPolicy')}</Link></li>
                </ul>
            </div>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: '50px', alignSelf: 'flex-start' }}>
                <p className='footerTitle'>{t('footer.contact.title')}</p>
                <p className='footerSubtitle'>{t('footer.contact.telephone')}</p>
                <p>603 96 37 52</p>
                <p className='footerSubtitle'>{t('footer.contact.mail')}</p>
                <p>Info@proinnovaconsultores.com</p>
            </div>
        </div>
    )
}