import "./CheckBox.css"

export default function CheckBox({ error, errorMessage, onChange, checked, children }) {
    return (
        <div className="checkBoxComponentContainer">
            <div className="checkBoxContainer">
                <input onChange={onChange} checked={checked} className="checkBoxInput" type="checkbox" />
                <p className="checkBoxLabel">{children}</p>
            </div>
                {GetErrorMessageIfError(error, errorMessage)}
        </div>
    )
}

function GetErrorMessageIfError(error, errorMessage) {
    if (error)
        return <p style={error ? { color: "red" } : {}} className="checkBoxError">{errorMessage}</p>
}