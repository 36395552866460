import { useTranslation } from "react-i18next";
import "./Services.css";
import brainstorming from "../../assets/images/Services/brainstorming.png";
import brainstormingWider from "../../assets/images/Services/brainstormingWider.png";
import { useEffect, useState } from "react";
import { HiOutlineLightBulb } from "react-icons/hi2";
import { PiFactory, PiGearSixLight } from "react-icons/pi";
import { GiWorld } from "react-icons/gi";
import { useLocation } from "react-router-dom";
import { GoTools } from "react-icons/go";

export default function Services() {
    const { t, i18n } = useTranslation('common');
    const [width, setWidth] = useState(window.innerWidth);
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);

        const params = new URLSearchParams(location.search);
        const elementId = params.get('scrollTo');
        if (elementId)
            scrollToElement(elementId)

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    var bodyContentIconAlign = width > 1100 ? "flex-start" : "";

    function scrollToElement(elementId) {
        var element = document.getElementById(elementId);
        var offset = 150;
        var elementPosition = element.getBoundingClientRect().top + window.scrollY;
        var offsetPosition = elementPosition - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }

    return (
        <div className="servicesContainer">
            <img style={{marginBottom: width > 1100 ? 100 : 90}} src={width > 1100 ? brainstormingWider : brainstorming} width={width > 1100 ? width - 20 : width} />
            <div style={width > 1100 ? { flexDirection: 'row' } : { flexDirection: 'column' }} className="headerBlocks">
                <div className="block1">
                    <HiOutlineLightBulb color="white" size={90} />
                    <p className="blockText">{t('services.idi')}</p>
                    <button onClick={() => scrollToElement("bodySection")} className="servicesButton">{t('services.go')}</button>
                </div>
                <div className="block2" style={width > 1100 ? { marginLeft: 40, marginRight: 40 } : { marginTop: 40, marginBottom: 40 }}>
                    <PiFactory color="white" size={90} />
                    <p className="blockText">{t('services.investment')}</p>
                    <button onClick={() => scrollToElement("investment")} className="servicesButton">{t('services.go')}</button>
                </div>
                <div className="block3" style={width > 1100 ? { marginRight: 40 } : { marginBottom: 40 }}>
                    <GiWorld color="white" size={90} />
                    <p className="blockText">{t('services.internationalization')}</p>
                    <button onClick={() => scrollToElement("internationalization")} className="servicesButton">{t('services.go')}</button>
                </div>
                <div className="block4">
                    <GoTools color="white" size={90} />
                    <p className="blockText">{t('services.engineering')}</p>
                    <button onClick={() => scrollToElement("engineering")} className="servicesButton">{t('services.go')}</button>
                </div>
            </div>
            <div className="servicesBody">
                <div id="bodySection" className="bodySection" style={width > 1100 ? { flexDirection: 'row' } : { flexDirection: 'column' }}>
                    <div style={{ flex: 1, alignSelf: bodyContentIconAlign }} className="blockBody">
                        <HiOutlineLightBulb color="#0d6aae" size={90} />
                        <p style={{ color: "#0d6aae" }} className="blockText">{t('services.idi')}</p>
                    </div>
                    <div className="bodyContent" style={width > 1100 ? { flex: 3 } : { flex: 1 }}>
                        <p className="servicesTitle">{t('services.idiTitle2')}</p>
                        <p className="servicesText">{t('services.idiText2')}</p>
                        <p className="servicesTitle">{t('services.idiTitle3')}</p>
                        <p className="servicesText">{t('services.idiText3')}</p>
                        <p className="servicesTitle">{t('services.idiTitle')}</p>
                        <p className="servicesText">{t('services.idiText')}</p>
                    </div>
                </div>
                <div className="bodySection" style={width > 1100 ? { flexDirection: 'row' } : { flexDirection: 'column' }}>
                    <div style={{ flex: 1, alignSelf: bodyContentIconAlign }} className="blockBody">
                        <PiFactory id="investment" color="#0d6aae" size={90} />
                        <p style={{ color: "#0d6aae" }} className="blockText">{t('services.investment')}</p>
                    </div>
                    <div className="bodyContent" style={width > 1100 ? { flex: 3 } : { flex: 1 }}>
                        <p className="servicesText">{t('services.investmentText')}</p>
                    </div>
                </div>
                <div className="bodySection" style={width > 1100 ? { flexDirection: 'row' } : { flexDirection: 'column' }}>
                    <div style={{ flex: 1, alignSelf: bodyContentIconAlign }} className="blockBody">
                        <GiWorld id="internationalization" color="#0d6aae" size={90} />
                        <p style={{ color: "#0d6aae" }} className="blockText">{t('services.internationalization')}</p>
                    </div>
                    <div className="bodyContent" style={width > 1100 ? { flex: 3 } : { flex: 1 }}>
                        <p className="servicesText">{t('services.internationalizationText')}</p>
                    </div>
                </div>
                <div className="bodySection" style={width > 1100 ? { flexDirection: 'row' } : { flexDirection: 'column' }}>
                    <div style={{ flex: 1, alignSelf: bodyContentIconAlign }} className="blockBody">
                        <GoTools id="engineering" color="#0d6aae" size={90} />
                        <p style={{ color: "#0d6aae", textWrap: "wrap" }} className="blockText">{t('services.engineering')}</p>
                    </div>
                    <div className="bodyContent" style={width > 1100 ? { flex: 3, alignItems: 'flex-start' } : { flex: 1 }}>
                        <p className="servicesText">
                            <ul style={{ listStyle: 'none' }}>
                                <li><PiGearSixLight size={25} style={{ color: '#0d6aae', marginRight: 7 }} /> {t('services.engineeringTexts.a')}</li>
                                <li><PiGearSixLight size={25} style={{ color: '#0d6aae', marginRight: 7 }} /> {t('services.engineeringTexts.b')}</li>
                                <li><PiGearSixLight size={25} style={{ color: '#0d6aae', marginRight: 7 }} /> {t('services.engineeringTexts.c')}</li>
                                <li><PiGearSixLight size={25} style={{ color: '#0d6aae', marginRight: 7 }} /> {t('services.engineeringTexts.d')}</li>
                                <li><PiGearSixLight size={25} style={{ color: '#0d6aae', marginRight: 7 }} /> {t('services.engineeringTexts.e')}</li>
                                <li><PiGearSixLight size={25} style={{ color: '#0d6aae', marginRight: 7 }} /> {t('services.engineeringTexts.f')}</li>
                                <li><PiGearSixLight size={25} style={{ color: '#0d6aae', marginRight: 7 }} /> {t('services.engineeringTexts.g')}</li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}