import doc from '../../assets/docs/LegalNotice.pdf';

export default function LegalNotice() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
            <iframe
                src={doc}
                title="Legal Notice"
                style={{
                    width: '100%',
                    height: '80vh',
                    border: 'none',
                }}
            />
        </div>
    )
}