export const api = "https://localhost:5001/api/"

export function aboutUsText(language) {
    if (language === "es")
        return (
            <p>
                <b>Altair</b> Consultores Logísticos fue fundada en el año 1996. Una compañía en la que, la creatividad, el valor añadido y la filosofía del trabajo a medida, son sus máximos referentes.<br /><br />

                Dentro de nuestro grupo, disponemos de estructura propia, tanto en Asia como en el subcontinente indio, con el agente asiático Union Power Shipping, con base en Hong Kong.<br /><br />

                Altair Consultores Logísticos nace con una <b>Visión y Misión</b>, diferente a la de la mayoría de las empresas del sector, pues lo que nos hace prácticamente <b>únicos</b> es el ser un Consultor de Comercio Exterior, complementando con la parte ejecutiva de procesos de transporte, logística y aduanas aplicables en todos los eslabones de la Cadena de Suministro.<br /><br />

                Altair Consultores Logísticos, es el socio de confianza, y posiblemente, con mayor transparencia en la gestión de su cadena de suministro.<br /><br />

                Somos una Empresa 100% española, con filosofía Glokal (Visión Global y Acción Local).<br /><br />

                Altair Consultores Logísticos cuenta con un equipo de cerca de 100 especialistas en comercio internacional y forwarding.<br /><br />

                La Oficina Central se encuentra en Madrid, y con oficinas propias en Santa Cruz de Tenerife, Las Palmas de Gran Canaria, Barcelona, Sevilla, Cádiz, Vigo,  Albacete, Gijón e Irún.<br /><br />
            </p>
        );
    else
        return (
            <p>
                Altair Consultores Logísticos was founded in 1996. A company in which creativity, added value and the philosophy of customized work are its greatest references.<br /><br />

                Within our group, we have our own structure, both in Asia and in the Indian subcontinent, with the Asian agent Union Power Shipping, based in Hong Kong.<br /><br />

                Altair Consultores Logísticos was born with a <b>Vision and Mission</b>, different from that of most companies in the sector, since what makes us practically <b>unique</b> is being a Trade Consultant Foreign, complementing the executive part of transportation, logistics and customs processes applicable in all links of the Supply Chain.<br /><br />

                Altair Logistics Consultants is the trusted partner, and possibly, with the greatest transparency in the management of your supply chain.<br /><br />

                We are a 100% Spanish Company, with Glokal philosophy (Global Vision and Local Action).<br /><br />

                Altair Consultores Logísticos has a team of nearly 100 specialists in international trade and forwarding.<br /><br />

                The Central Office is located in Madrid, and with its own offices in Santa Cruz de Tenerife, Las Palmas de Gran Canaria, Barcelona, ​​Seville, Cádiz, Vigo, Albacete, Gijón and Irún.<br /><br />
            </p>
        );
}
