import React, { useCallback, useEffect, useState } from "react";
import "./AuthModal.css";
import { animated } from "react-spring";
import { useTranslation } from "react-i18next";
import TextInput from "../TextInput/TextInput";
import { IoLogIn } from "react-icons/io5";
import { Loader } from "../Loader/Loader";
import { toast } from "react-toastify";
import { api } from "../../utils/Constants";
import { savedToken } from "../../utils/variables";
import CheckBox from "../CheckBox/CheckBox";
// import { getCookieConsentValue } from "react-cookie-consent";
// import { useCookies } from 'react-cookie'

export function AuthModal({ toggleVisible, visible, rotation = 20, timing = 150, setAdminMode }) {
    const [isBooped, setIsBooped] = useState(false);
    const [token, setToken] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rememberToken, setRememberToken] = useState(false);
    // const [rememberCookie, setRememberCookie] = useCookies(['rememberToken']);

    const style = {
        display: 'inline-block',
        backfaceVisibility: 'hidden',
        transform: isBooped
            ? `rotate(${rotation}deg)`
            : `rotate(0deg)`,
        transition: `transform ${timing}ms`
    };

    const escFunction = useCallback((event) => {
        if (event.key === "Escape" && visible === true) {
            toggleVisible();
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        if (!isBooped) {
            return;
        }
        const timeoutId = window.setTimeout(() => {
            setIsBooped(false);
        }, timing);
        return () => {
            window.clearTimeout(timeoutId);
        };
    }, [isBooped, timing]);

    const trigger = () => {
        setIsBooped(true);
    };

    async function onSubmit() {
        await setLoading(true);
        await fetch(api + "Security/Authenticate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "accept": "*/*",
                "Authorization": token
            }
        }).then(async (response) => {
            if (response.status === 200) {
                toast.success(t('apiResponse.success'), {
                    position: "bottom-left"
                })
                savedToken.value = `Bearer ${token}`;
                // if(rememberToken)
                //     await setRememberCookie('rememberToken', `Bearer ${token}`)
                await setAdminMode(true);
                await toggleVisible();
            }
            else {
                var responseJson = await response.json();
                toast.error(responseJson.Message, {
                    position: "bottom-left"
                })
                setError(true);
            }
        })
        await setLoading(false);
    }

    async function toggleRememberToken(){
        await setRememberToken(!rememberToken);
    }    

    // function getCheckboxIfCookiesAccepted() {
    //     var cookiesAccepted = getCookieConsentValue();
    //     if (cookiesAccepted != undefined && cookiesAccepted === "true")
    //         return (
    //             <CheckBox checked={rememberToken} onChange={toggleRememberToken}>
    //                 {t('login.tokenSave')}
    //             </CheckBox>
    //     );
    // }

    const { t } = useTranslation('common');
    if (visible)
        return (
            <div className="genericModalOverlay">
                <Loader visible={loading} />
                <div className="AuthModal">
                    <div className="modalHeaderDiv" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 40, paddingRight: 40 }}>
                        <h1 style={{ color: "rgb(221, 221, 221)", flex: 10, display: "flex", justifyContent: "center", paddingLeft: "2rem" }}>{t('login.title')}</h1>
                        <div style={{ flex: 1, display: "flex", justifyContent: 'flex-end' }}>
                            <animated.div onClick={toggleVisible} onMouseEnter={trigger} style={style}>
                                <div className="divCloseModal">
                                    <button>X</button>
                                </div>
                            </animated.div>
                        </div>
                    </div>
                    <div style={{ margin: 10, overflow: 'auto', padding: 40, paddingTop: 0, display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <TextInput value={token} onChange={setToken} error={error} errorMessage={t('login.errorMessage')}>
                            Token:
                        </TextInput>
                        {/* {getCheckboxIfCookiesAccepted()} */}
                        <button onClick={onSubmit} className="authSendButton">{t('login.title')}<IoLogIn style={{ marginLeft: 7 }} size={26} /></button>
                    </div>
                </div>
            </div>
        )
}