import { Link, useMatch, useResolvedPath } from "react-router-dom";
import "./CustomLink.css";

export default function CustomLink({ icon = null, to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })

    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>
                <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                    {icon}
                    <p className="customLink">{children}</p>
                </div>
            </Link>
        </li>
    )
}