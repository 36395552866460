import doc from '../../assets/docs/DataProtectionPolicy.pdf';

export default function DataProtectionPolicy() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
            <iframe
                src={doc}
                title="Legal Notice"
                style={{
                    width: '100%',
                    height: '80vh',
                    border: 'none',
                }}
            />
        </div>
    );
}
