import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_es from "./translations/es/common.json";
import common_en from "./translations/en/common.json";
// import Cookies from 'js-cookie';
// import { getCookieConsentValue } from 'react-cookie-consent';


i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: GetLanguage(),                               // language to use
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    es: {
      common: common_es
    },
  },
});

function GetLanguage() {
  // var cookiesAccepted = getCookieConsentValue();

  // if (cookiesAccepted != undefined && cookiesAccepted === "true") {
  //   var language = Cookies.get('languageWebCode');
  //   return (language != 'es' && language != 'en') ? 'es' : language
  // }
  return 'es';
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
