import "./Navbar.css"
import { Link } from "react-router-dom";
import logo from '../../assets/images/Logo/logo proinnova.png'
import CustomLink from "../CustomLink/CustomLink";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PiIntersectDuotone } from "react-icons/pi";
import { GrServices } from "react-icons/gr";
// import Cookies from 'js-cookie';
// import { getCookieConsentValue } from "react-cookie-consent";
import { FaUnlockAlt } from "react-icons/fa";
import { animated } from "react-spring";
import { savedToken } from "../../utils/variables";
import { RiAdminFill } from "react-icons/ri";
import { FaInstagram } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { PiHouseLine } from "react-icons/pi";

export default function Navbar({ rotation = 20, timing = 150, onLockClick, adminMode }) {
    const [t, i18n] = useTranslation('common');
    let images = [require('../../assets/images/Flags/spain.png'), require('../../assets/images/Flags/uk.jpg')]
    let [image, toggle] = useState(GetStoredLanguage());
    const [isBooped, setIsBooped] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    const logoWidth = 276;
    const logoHeight = 57;

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const style = {
        display: 'inline-block',
        backfaceVisibility: 'hidden',
        transform: isBooped
            ? `rotate(${rotation}deg)`
            : `rotate(0deg)`,
        transition: `transform ${timing}ms`
    };

    useEffect(() => {
        if (!isBooped) {
            return;
        }
        const timeoutId = window.setTimeout(() => {
            setIsBooped(false);
        }, timing);
        return () => {
            window.clearTimeout(timeoutId);
        };
    }, [isBooped, timing]);

    const trigger = () => {
        setIsBooped(true);
    };

    function GetStoredLanguage() {
        // var cookiesAccepted = getCookieConsentValue();

        // if (cookiesAccepted != undefined && cookiesAccepted === "true") {
        //     var language = Cookies.get('languageWebCode');
        //     if (language === "en")
        //         return images[1]
        // }
        return images[0]
    }

    function GetImage() {
        // var cookiesAccepted = getCookieConsentValue();
        if (images[0] === image) {
            i18n.changeLanguage('en');
            // if (cookiesAccepted != undefined && cookiesAccepted === "true")
            //     Cookies.set('languageWebCode', 'en');
            return images[1]
        }
        else {
            i18n.changeLanguage('es');
            // if (cookiesAccepted != undefined && cookiesAccepted === "true")
            //     Cookies.set('languageWebCode', 'es');
            return images[0]
        }
    }

    function GetAdminModeLink() {
        if (adminMode)
            return (
                <CustomLink icon={<RiAdminFill size={20} />} to="/admin">{t('header.admin')}</CustomLink>
            );
    }

    function GetLockIfNotLoggedIn() {
        if (!adminMode)
            return (
                <animated.div onClick={onLockClick} onMouseEnter={trigger} style={style}>
                    <FaUnlockAlt size={20} />
                </animated.div>
            );
    }

    function GetNavigation() {
        if (width > 1100)
            return (
                <ul>
                    <CustomLink icon={<PiHouseLine size={20} />} to="/">{t('header.home')}</CustomLink>
                    <CustomLink icon={<GrServices size={20} />} to="/services">{t('header.services')}</CustomLink>
                    <CustomLink icon={<PiIntersectDuotone size={20} />} to="/sectors">{t('header.sectors')}</CustomLink>
                    <CustomLink icon={<FiMail size={20} />} to="/contact">{t('header.contact')}</CustomLink>
                    {/* {GetAdminModeLink()} */}
                </ul>
            );
    }

    return (
        <header className="nav">
            <div className="preHeader">
                {/* <a target='_blank' style={{ cursor: 'pointer' }} href='https://www.instagram.com/proinnovaconsultores'>
                    <FaInstagram color="white" size={20} />
                </a> */}
            </div>
            <div className="header">
                <Link to="/" className="site-title-container">
                    <img width={width > 1100 ? logoWidth : logoWidth / 2.5} height={width > 1100 ? logoHeight : logoHeight / 2.5} src={logo} />
                    <p className="site-title"></p>
                </Link>
                <div className="headerRightContainer">
                    {GetNavigation()}
                    {/* {GetLockIfNotLoggedIn()} */}
                    <img onClick={() => toggle(GetImage())} className="flag" width={25} height={15} src={image} />
                </div>
            </div>
        </header>
    )
}
