import React from "react";
import "./Loader.css";
import { Hourglass } from 'react-loader-spinner';

export function Loader({ visible }) {
    if (visible)
        return (
            <React.Fragment>
                <div className="modalOverlay">
                    <div className="modal">
                        <Hourglass
                            visible={true}
                            height="80"
                            width="80"
                            color="#0d6bae"
                            ariaLabel="revolving-dot-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </div>
            </React.Fragment>
        )
}